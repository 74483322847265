import React, { Suspense, lazy } from 'react';


import { RouterProvider, createBrowserRouter } from 'react-router-dom';


const NewsComponent = lazy(() => import('./FirstComponent.js'));

const App = () => {

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense>
          <NewsComponent />
        </Suspense>
      )
    },
    // {
    //   path: 'news/:id',
    //   element: <ShowNewsComponent />
    // },
    // {
    //   path: 'login',
    //   element: <AuthorizationComponent />
    // },
    // {
    //   path: 'network',
    //   element: <NetworkComponent />
    // },
    // {
    //   path: 'latest-news',
    //   element: <NewsListComponent />
    // },
    // {
    //   path: 'create-news/:id',
    //   element: <NewsComponent />
    // },
   
    // {
    //   path: 'mining',
    //   element:(
    //     <Suspense>
    //       <MiningComponent />
    //     </Suspense>
    //   )
    // },
  
  ]);

  return <RouterProvider router={router} />;

  
};

export default App;
